import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        redirect: '/main1',
        component: () => import('../views/home/index.vue'),
        children: [
            {
                path: 'main1',
                component: () => import('../views/main/main1.vue'),
            },
            {
                path: 'main2',
                component: () => import('../views/main/main2.vue'),
            },
            {
                path: 'main3',
                component: () => import('../views/main/main3.vue'),
                redirect: 'main3/news1',
                children: [
                    {
                        path: 'news1',
                        name: 'news1',
                        component: () => import('../views/main/news/index1.vue'),
                    },
                    {
                        path: 'news2',
                        name: 'news2',
                        component: () => import('../views/main/news/index2.vue'),
                    },
                    {
                        path: 'news3',
                        name: 'news3',
                        component: () => import('../views/main/news/index3.vue'),
                    },
                    {
                        path: 'detail',
                        name: 'detail',
                        component: () => import('../views/main/news/detail.vue'),
                    }
                ]
            },
            {
                path: 'main4',
                component: () => import('../views/main/main4.vue'),
            },
            {
                path: 'caseDetail',
                component: () => import('../views/main/friend/detail.vue'),
            },
            {
                path: 'main5',
                component: () => import('../views/main/main5.vue'),
                children: [
                    {
                        path: 'activity',
                        component: () => import('../views/main/activity/index1.vue'),
                    },
                ]
            },
            {
                path: 'main6',
                component: () => import('../views/main/main6.vue'),
                redirect: 'main6/about1',
                children: [
                    {
                        path: 'about1',
                        component: () => import('../views/main/about/index1.vue'),
                    },
                    {
                        path: 'about2',
                        component: () => import('../views/main/about/index2.vue'),
                    },
                    {
                        path: 'about3',
                        component: () => import('../views/main/about/index3.vue'),
                    },
                    {
                        path: 'about4',
                        component: () => import('../views/main/about/index4.vue'),
                    }
                ]
            },

        ],
        scrollBehavior(to, from, savedPosition) {
            //if判断可加可不加、根据自己需求
            //savedPosition当且仅当通过浏览器的前进/后退按钮触发时才可用
            if (savedPosition) {
                return savedPosition
            }
            return {
                x: 0,
                y: 0
            }
        }

    },

]


// 解决重复导航报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes,
})


export default router
